import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { RoutePaths } from 'src/core/router/RoutePaths';
import LogoImage from 'src/icons/logo.svg';
import { LOGO_LOCAL_STORAGE_KEY } from 'src/pages/StylerPage/StylerPage';
import { ThemeStore } from 'src/stores/ThemeStore/ThemeStore';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';
import styled from 'styled-components';

const StyledWrapper = styled(Link)`
    color: #0d0f1c;
    display: flex;
    margin-right: 85px;

    &:hover {
        text-decoration: none;
        color: #0d0f1c;
    }
`;

const logoFromLocalStorage = LocalStoreHelper.getItem(LOGO_LOCAL_STORAGE_KEY);

export const Logo = observer(() => {
    return (
        <StyledWrapper to={RoutePaths.index}>
            <StyledImage
                maxWidth={ThemeStore.getLogoMaxSize}
                src={logoFromLocalStorage || LogoImage}
            />
        </StyledWrapper>
    );
});

const StyledImage = styled.img<{ maxWidth?: string }>`
    max-width: ${(props) => props.maxWidth};
    width: 180px;
`;
